<template>
  <div class="project">
    <section class="project">
      <main>
        <p class="thin small" id="tag">{{ projectdetail.types }}</p>
        <h1>{{ projectdetail.title }}</h1>
        <p class="thin" id="de">{{ projectdetail.textde }}</p>
        <p id="en">{{ projectdetail.texten }}</p>
        <figure v-for="(link, key) in projectdetail.links" :key="link">
          <a :href="`${link}`">{{ key }}</a>
        </figure>
      </main>
      <video v-for="film in projectdetail.films" :key="film" controls="controls">
        <source :src="require(`@/assets/${film}`)" type="video/mp4">
      </video>
      <figure v-for="image in projectdetail.images" :key="image">
        <img :src="require(`@/assets/${image}`)" :alt="projectdetail.title">
      </figure>
    </section>
  </div>
</template>

<script>
import projects from "@/projects.js";
export default {
  data() {
    return {
      projectId: this.$route.params.id
    };
  },
       computed: {
         projectdetail(){
          return projects.projectdetails.find(
            projectdetail => projectdetail.id === this.projectId
          );
       }
    }
};
</script>


<style scoped>
h1{
  z-index: 1;
}
a {
  font-family: aktiv-grotesk, sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  position: fixed;
  overflow: auto;
  left: 4vw;
  top: 600px;
  width: 350px;
  color: mediumpurple;
}

#tag {
  font-family: aktiv-grotesk-extended, sans-serif;
  top: 2vh;
  text-transform: uppercase;
}

/* styling images, videos */

img, video {
  position: relative;
  margin-left: auto;
  display: block;
  top: 15vw;
  width: 40vw;
  overflow-y: scroll;
}

video {
  margin-right: 2vw;
}

@media screen and (max-width: 992px) {
  img {
    margin-left: 10vw;
    top: 200px;
    width: 60vw;
  }
  video {
    display: none;
  }
  p{
    left: 6vw;
  }
  a {
    position: relative;
    top: 170px;
  }
}
@media screen and (max-width: 576px) {
  h1 {
    font-size: 56px;
  }
}
</style>
